<script setup>
useHead({
  htmlAttrs: {
    class: "layout-main",
  },
});
</script>

<template>
  <main class="main">
    <MainHeader class="header"> </MainHeader>
    <MainBody class="body">
      <slot />
    </MainBody>
  </main>
</template>

<style lang="scss">
html.layout-main {
  background-color: var(--main-body-background-color);
  color: var(--main-body-text-color);
}

html.layout-main a {
  color: var(--main-body-text-color);
  // text-decoration: none;
}
</style>

<style lang="scss" scoped>
.main {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: stretch;
  align-self: center;
  width: 100%;
}

.body {
  width: 100%;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
}
</style>
